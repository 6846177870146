import { init, FullStory as fs } from '@fullstory/browser';
import * as Cookie from '@integrabeauty/cookie';
import { isValidEmailAddress } from '@integrabeauty/person';
import { VisitorConsentCollectedEvent } from '@integrabeauty/shopify-browser-types';
import { hasAnalyticsConsent } from '../../lib/shopify-consent.js';

const listenerMap: Record<string, (event: any)=> void> = {
  'attentive-lead-captured': onAttentiveLeadCaptured,
  'bis-form-submitted': onBISFormSubmitted,
  'cart-updated': onCartUpdated,
  'cart-update-erred': onCartUpdateErred,
  'checkout-started': onCheckoutStarted,
  'hair-quiz-completed': onHairQuizCompleted,
  'marketing-subscribed': onMarketingSubscribed,
  'product-variant-viewed': onProductVariantViewed,
  'searched': onSearched,
  'shoppable-video-viewed': onShoppableVideoViewed,
  'typeform-submitted': onTypeformSubmitted,
  'visitor-authenticated': onVisitorAuthenticated,
  'yotpo-question-created': onYotpoQuestionCreated,
  'yotpo-review-submitted': onYotpoReviewSubmitted
};

function onSearched(event: WindowEventMap['searched']) {
  if (event.detail.error) {
    return;
  }

  if (typeof event.detail.query !== 'string') {
    return;
  }

  fs('trackEvent', {
    name: 'Products Searched',
    properties: {
      query: event.detail.query,
      result_count: event.detail.results?.length,
      shopify_country: Shopify.country,
      shopify_currency: Shopify.currency?.active,
      shopify_theme_name: Shopify.theme?.name
    }
  });
}

function onTypeformSubmitted(event: WindowEventMap['typeform-submitted']) {
  if (event.detail.error_message) {
    return;
  }

  const options = <Record<string, any>>{
    properties: {}
  };

  const shopifyClientId = getShopifyClientId();
  if (shopifyClientId) {
    options.uid = shopifyClientId;
  }

  if (event.detail.email) {
    options.properties.email = event.detail.email;
  }

  if (event.detail.phone) {
    options.properties.phone = event.detail.phone;
  }

  if (Object.keys(<Record<string, any>>options.properties).length > 0) {
    fs('setIdentity', options);
  }

  fs('trackEvent', {
    name: 'Typeform Survey Response Submitted',
    properties: {
      ...event.detail,
      shopify_country: Shopify.country,
      shopify_currency: Shopify.currency?.active,
      shopify_theme_name: Shopify.theme?.name
    }
  });
}

function onShoppableVideoViewed(event: WindowEventMap['shoppable-video-viewed']) {
  fs('trackEvent', {
    name: 'Shoppable Video Viewed',
    properties: {
      percent_viewed: event.detail.percent_viewed,
      shopify_country: Shopify.country,
      shopify_currency: Shopify.currency?.active,
      shopify_theme_name: Shopify.theme?.name,
      video_id: event.detail.video_id
    }
  });
}

function onAttentiveLeadCaptured(event: WindowEventMap['attentive-lead-captured']) {
  const options = <Record<string, any>>{
    properties: {}
  };

  const shopifyClientId = getShopifyClientId();
  if (shopifyClientId) {
    options.uid = shopifyClientId;
  }

  if (event.detail.email) {
    options.properties.email = event.detail.email;
  }

  if (event.detail.phone) {
    options.properties.phone = event.detail.phone;
  }

  if (Object.keys(<Record<string, any>>options.properties).length > 0) {
    fs('setIdentity', options);
  }

  if (event.detail.action === 'EMAIL_LEAD') {
    fs('trackEvent', {
      name: 'Attentive Email Lead Captured',
      properties: {
        ...event.detail,
        shopify_country: Shopify.country,
        shopify_currency: Shopify.currency?.active,
        shopify_theme_name: Shopify.theme?.name
      }
    });
  } else if (event.detail.action === 'LEAD') {
    fs('trackEvent', {
      name: 'Attentive Lead Captured',
      properties: {
        ...event.detail,
        shopify_country: Shopify.country,
        shopify_currency: Shopify.currency?.active,
        shopify_theme_name: Shopify.theme?.name
      }
    });
  }
}

/**
 * When a back in stock sign up form is submitted, we possibly learn of a new email address. Use
 * this to better identify visitors in FullStory. Also record a custom event.
 */
function onBISFormSubmitted(event: WindowEventMap['bis-form-submitted']) {
  if (event.detail.success) {
    const email = event.detail.email;
    const shopifyClientId = getShopifyClientId();

    if (isValidEmailAddress(email) && shopifyClientId) {
      fs('setIdentity', {
        uid: shopifyClientId,
        properties: {
          email
        }
      });
    }

    fs('trackEvent', {
      name: 'Back In Stock Sign Up Form Submitted',
      properties: {
        product_id: event.detail.product_id,
        product_title: event.detail.product_title,
        shopify_country: Shopify.country,
        shopify_currency: Shopify.currency?.active,
        shopify_theme_name: Shopify.theme?.name,
        sku: event.detail.sku,
        variant_id: event.detail.variant_id,
        variant_title: event.detail.variant_title
      }
    });
  }
}

function onVisitorAuthenticated(event: WindowEventMap['visitor-authenticated']) {
  const shopifyClientId = getShopifyClientId();
  if (!shopifyClientId) {
    return;
  }

  const customerId = event.detail.id;
  const email = event.detail.email;
  const displayName = buildDisplayName(event);

  const properties: Record<string, any> = {};

  if (displayName) {
    properties['displayName'] = displayName;
  }

  if (Number.isInteger(customerId)) {
    properties['customer_id'] = customerId;
  }

  if (isValidEmailAddress(email)) {
    properties['email'] = email;
  }

  if (Object.keys(properties).length > 0) {
    fs('setIdentity', {
      uid: shopifyClientId,
      properties
    });
  }

  fs('trackEvent', {
    name: 'Login Succeeded',
    properties: {
      shopify_country: Shopify.country,
      shopify_currency: Shopify.currency?.active,
      shopify_theme_name: Shopify.theme?.name
    }
  });
}

function buildDisplayName(event: WindowEventMap['visitor-authenticated']) {
  const name = event.detail.name;
  const firstName = event.detail.first_name;
  const lastName = event.detail.last_name;

  if (name) {
    return name;
  }

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (lastName) {
    return lastName;
  }

  if (firstName) {
    return firstName;
  }
}

function onYotpoReviewSubmitted(event: WindowEventMap['yotpo-review-submitted']) {
  const options = <Record<string, any>>{
    properties: {}
  };

  const shopifyClientId = getShopifyClientId();
  if (shopifyClientId) {
    options.uid = shopifyClientId;
  }

  if (event.detail.email) {
    options.properties.email = event.detail.email;
  }

  if (Object.keys(<Record<string, any>>options.properties).length > 0) {
    fs('setIdentity', options);
  }

  fs('trackEvent', {
    name: 'Yotpo Product Review Submitted',
    properties: {
      body: event.detail.body,
      product_id: event.detail.product_id,
      product_title: event.detail.product_title,
      shopify_country: Shopify.country,
      shopify_currency: Shopify.currency?.active,
      shopify_theme_name: Shopify.theme?.name
    }
  });
}

function onHairQuizCompleted(event: WindowEventMap['hair-quiz-completed']) {
  fs('trackEvent', {
    name: 'Hair Quiz Completed',
    properties: {
      recommendation_count: event.detail.number_of_recommendations
    }
  });
}

function onYotpoQuestionCreated(event: WindowEventMap['yotpo-question-created']) {
  const options = <Record<string, any>>{
    properties: {}
  };

  const shopifyClientId = getShopifyClientId();
  if (shopifyClientId) {
    options.uid = shopifyClientId;
  }

  if (event.detail.email) {
    options.properties.email = event.detail.email;
  }

  if (Number.isInteger(event.detail.customer_id)) {
    options.properties.customer_id = event.detail.customer_id.toString();
  }

  if (Object.keys(<Record<string, any>>options.properties).length > 0) {
    fs('setIdentity', options);
  }

  fs('trackEvent', {
    name: 'Yotpo Product Question Submitted',
    properties: {
      body: event.detail.body,
      product_title: event.detail.product_title,
      product_url: event.detail.product_url,
      shopify_country: Shopify.country,
      shopify_currency: Shopify.currency?.active,
      shopify_theme_name: Shopify.theme?.name
    }
  });
}

/**
 * Relay various events to FullStory when cart state updates.
 *
 * @see https://github.com/fullstorydev/fullstory-browser-sdk#sending-custom-events
 * @see https://developer.fullstory.com/browser/custom-properties/
 */
function onCartUpdated(event: WindowEventMap['cart-updated']) {
  if (event.detail.is_initial) {
    fs('trackEvent', {
      name: 'Cart State Initialized',
      properties: event.detail.cart
    });
  }

  for (const variant of event.detail.variants_added) {
    fs('trackEvent', {
      name: 'Product Added',
      properties: {
        product_id: variant.product_id,
        product_title: variant.product_title,
        product_Type: variant.product_type,
        scenario: event.detail.scenario,
        shopify_country: Shopify.country,
        shopify_currency: Shopify.currency?.active,
        shopify_theme_name: Shopify.theme?.name,
        sku: variant.sku,
        variant_id: variant.variant_id,
        variant_title: variant.variant_title
      }
    });
  }

  for (const variant of event.detail.variants_removed) {
    fs('trackEvent', {
      name: 'Product Removed',
      properties: {
        product_id: variant.product_id,
        product_title: variant.product_title,
        product_Type: variant.product_type,
        scenario: event.detail.scenario,
        shopify_country: Shopify.country,
        shopify_currency: Shopify.currency?.active,
        shopify_theme_name: Shopify.theme?.name,
        sku: variant.sku,
        variant_id: variant.variant_id,
        variant_title: variant.variant_title
      }
    });
  }

  for (const code of event.detail.discount_codes_added) {
    fs('trackEvent', {
      name: 'Discount Code Added',
      properties: {
        discount_code: code,
        shopify_country: Shopify.country,
        shopify_currency: Shopify.currency?.active,
        shopify_theme_name: Shopify.theme?.name
      }
    });
  }

  for (const code of event.detail.discount_codes_removed) {
    fs('trackEvent', {
      name: 'Discount Code Removed',
      properties: {
        discount_code: code,
        shopify_country: Shopify.country,
        shopify_currency: Shopify.currency?.active,
        shopify_theme_name: Shopify.theme?.name
      }
    });
  }
}

function onCartUpdateErred(event: WindowEventMap['cart-update-erred']) {
  fs('trackEvent', {
    name: 'Cart Update Erred',
    properties: {
      cart_skus: event.detail.cart?.items?.map(item => item.sku),
      error_code: event.detail.code,
      error_name: event.detail.error?.name,
      error_message: event.detail.error?.message,
      inputs: event.detail.inputs,
      scenario: event.detail.scenario,
      shopify_country: Shopify.country,
      shopify_currency: Shopify.currency?.active,
      shopify_theme_name: Shopify.theme?.name,
      transaction_id: event.detail.transaction?.id,
      transaction_name: event.detail.transaction?.name
    }
  });
}

function onCheckoutStarted(event: WindowEventMap['checkout-started']) {
  const cart = event.detail.cart;

  // We are intentionally not reusing the getApplicableDiscountCodes helper function here because we
  // need this code to be independent from common libraries. In the future, this pixel code might be
  // running in a separate app where the helper function is unavailable.
  let codes: string[] = [];
  if (Array.isArray(cart.discount_codes)) {
    codes = cart.discount_codes
      .filter(code => code.applicable)
      .map(code => code.code)
      .map(code => code.toUpperCase());
  }

  fs('trackEvent', {
    name: 'Checkout Started',
    properties: {
      currency: cart.currency,
      discount_codes: codes,
      estimated_total: cart.total_price / 100,
      shopify_country: Shopify.country,
      shopify_currency: Shopify.currency?.active,
      shopify_theme_name: Shopify.theme?.name,
      skus: cart.items.map(item => item.sku)
    }
  });
}

function onProductVariantViewed(event: WindowEventMap['product-variant-viewed']) {
  fs('trackEvent', {
    name: 'Product Viewed',
    properties: {
      product_title: event.detail.product_title,
      product_id: event.detail.product_id?.toString(),
      shopify_country: Shopify.country,
      shopify_currency: Shopify.currency?.active,
      shopify_theme_name: Shopify.theme?.name,
      sku: event.detail.sku,
      variant_id: event.detail.variant_id?.toString(),
      variant_title: event.detail.variant_title
    }
  });
}

function onMarketingSubscribed(event: WindowEventMap['marketing-subscribed']) {
  if (!event.detail.success) {
    return;
  }

  fs('trackEvent', {
    name: 'Signed Up',
    properties: {
      shopify_country: Shopify.country,
      shopify_currency: Shopify.currency?.active,
      shopify_theme_name: Shopify.theme?.name,
      subscribed_from: event.detail.subscribed_from,
      subscribe_method: event.detail.subscribe_method
    }
  });
}

/**
 * Retrieve the session ID or a link to replay of the current session. The link can point to the
 * beginning of the session or to the current moment time.
 *
 * A session url or ID can only be returned by FS('getSession') if capture has begun. Before this
 * `FS('getSession')` returns null or undefined.
 *
 * @see https://developer.fullstory.com/browser/get-session-details/
 */
function captureSessionId() {
  fs('observe', {
    type: 'start',
    callback: () => {
      const id = fs('getSession', { format: 'id' });
      if (id) {
        try {
          sessionStorage.setItem('fullstory_session_id', id);
        } catch (error) {
          console.log(error);
        }
      }
    }
  });
}

/**
 * @todo eventually we should only bother to set uid if it has changed, right now we need to ensure
 * it is set to our own preferred value instead of the default
 *
 * @see https://developer.fullstory.com/browser/identification/identify-users/
 */
function identify() {
  const options = <Record<string, any>>{};

  // We are using the anonymous Shopify client id because it comes from a server-side cookie. Using
  // FullStory's own id mechanism does not work anymore because of Apple ITP.
  const shopifyClientId = getShopifyClientId();
  if (shopifyClientId) {
    options.uid = shopifyClientId;
    options.consent = hasAnalyticsConsent();
    fs('setIdentity', options);
  }
}

function getShopifyClientId() {
  try {
    return Cookie.read('_shopify_y');
  } catch (error) {}
}

function shouldInit() {
  let isTop = false;
  try {
    isTop = top === window;
  } catch (error) {}

  if (!isTop) {
    return false;
  }

  return true;
}

/**
 * Shopify emitted event that indicates the user has changed their consent settings. This handler
 * should be responsible for updating the fbq consent state based on the passed in values.
 *
 * @see https://shopify.dev/docs/api/customer-privacy#use-an-event-listener
 */
function onVisitorConsentCollected(event: VisitorConsentCollectedEvent) {
  fs('setIdentity', { consent: event.detail.analyticsAllowed });
  if (event.detail.analyticsAllowed) {
    fs('restart');
  } else {
    fs('shutdown');
  }
}

/**
 * We want to manually capture data to delay the call to start tracking so that we can check for
 * consent in between init and start.
 *
 * On certain pages an unknown third party is loading FullStory sometimes. To protect against this
 * we set an explicit namespace that is very unlikely to conflict.
 *
 * @see https://developer.fullstory.com/browser/auto-capture/capture-data/#manually-delay-data-capture
 * @see https://github.com/fullstorydev/fullstory-browser-sdk?tab=readme-ov-file#configuration-options
 * @see https://help.fullstory.com/hc/en-us/articles/360020624694-What-if-the-identifier-FS-is-used-by-another-script-on-my-site
 */
function main() {
  if (!shouldInit()) {
    return;
  }

  init({
    debug: false,
    namespace: 'integra_lange_fs',
    orgId: '113MY3',
    startCaptureManually: true
  });

  // Start tracking
  fs('start');
  identify();
  captureSessionId();

  if (!hasAnalyticsConsent()) {
    fs('shutdown');
  }

  // unhook the loader
  for (const eventType in listenerMap) {
    removeEventListener(eventType, fullstory_event_listener);
  }

  // replay prior events
  for (const event of fullstory_event_queue) {
    try {
      listenerMap[event?.type]?.(event);
    } catch (error) {}
  }

  // clear the loader (idempotency/leaks)
  fullstory_event_queue = [];

  // listen for new events
  for (const type in listenerMap) {
    addEventListener(type, listenerMap[type]);
  }

  document.addEventListener('visitorConsentCollected', onVisitorConsentCollected);
}

main();
