import * as Cookie from '@integrabeauty/cookie';

type ShopifyConsentLimitation = 'CCPA_BLOCK_ALL' | 'GDPR';

interface ShopifyConsent {
  con: {
    CMP?: {
      a: '0' | '1';
      m: '0' | '1';
      p: '0' | '1';
      s: '0' | '1';
    };
    GDPR?: '' | '0' | '1' | 'no_interaction';
  };
  display_banner?: boolean;
  lim: ShopifyConsentLimitation[];
  purposes: {
    a: boolean;
    m: boolean;
    p: boolean;
    t: boolean;
  };
  reg: '' | 'CCPA' | 'GDPR';
  region?: string;
  sale_of_data_region?: boolean;
  v: string;
}

function readShopifyConsentCookie() {
  let cookie;
  try {
    cookie = Cookie.read('_tracking_consent');
  } catch (error) {}

  if (!cookie) {
    return null;
  }

  let consent;
  try {
    consent = JSON.parse(cookie);
  } catch (error) {}

  if (!isShopifyConsentObject(consent)) {
    return null;
  }

  return consent;
}

/**
 * We use the Shopify consent API to determine if a user has given consent to marketing tracking.
 * Although Shopify does provide a client-side mechanism to load the customer privacy API and
 * supporting methods/functionality, we have made the decision to use the Shopify set
 * "_tracking_consent" cookie to prevent pixels from having to wait for an asynchronous call to load
 * the API module.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/globalPrivacyControl
 */
export function hasMarketingConsent() {
  if (navigator.globalPrivacyControl === true) {
    return false;
  }

  const consent = readShopifyConsentCookie();
  if (!consent) {
    return true;
  }

  return consent.purposes.m;
}

/**
 * We use the Shopify consent API to determine if a user has given consent to analytics tracking.
 * Although Shopify does provide a client-side mechanism to load the customer privacy API and
 * supporting methods/functionality, we have made the decision to use the Shopify set
 * "_tracking_consent" cookie to prevent pixels from having to wait for an asynchronous call to load
 * the API module.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/globalPrivacyControl
 */
export function hasAnalyticsConsent() {
  if (navigator.globalPrivacyControl === true) {
    return false;
  }

  const consent = readShopifyConsentCookie();
  if (!consent) {
    return true;
  }

  return consent.purposes.a;
}

function isShopifyConsentObject(value: any): value is ShopifyConsent {
  return typeof value === 'object' && value !== null && value.con !== null &&
    value.sale_of_data_region !== null && value.purposes !== null;
}
